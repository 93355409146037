import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Porto.css";

const Porto = () => {
  return (
    <div>
      <Container fluid className="noPadding ">
        <Row className="backgroundBlue homeHeader">
          <Col md={12} className="textCenter noPadding">
            <div className="headerCityDiv">
              <Image
                fluid
                className="headerIllustration"
                src="/assets/Porto.png"
              ></Image>
              <div className="headerOverlay">
                <div className="headerOverlayBlue"></div>
                <Image
                  className="headerTilesOverlay"
                  src="/assets/tilesOverlay02.png"
                ></Image>
                <h1 className="headerH1">Porto</h1>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigMarginTop noPadding">
          <Col md={6} className="cityDescription noPadding">
            <div>
              <h1>Porto</h1>
              <h2>City of Wine and Panoramas</h2>
              <p>
                Welcome to Porto, Portugal’s enchanting northern gem, where
                old-world charm meets a vibrant spirit. Set along the banks of
                the majestic Douro River, Porto is famous for its stunning
                bridges, colorful riverside houses, and world-renowned Port
                wine. Wander through the historic Ribeira district, a UNESCO
                World Heritage site, with its cobbled streets, lively cafés, and
                picturesque views. Explore iconic landmarks like the Clérigos
                Tower and the Livraria Lello, one of the most beautiful
                bookstores in the world. And of course, no visit is complete
                without sampling the city’s famous wine in the wine cellars of
                Vila Nova de Gaia. With its rich culture, warm locals, and
                breathtaking scenery, Porto invites you to experience the magic
                that makes this city so unforgettable.
              </p>
            </div>
          </Col>
          <Col md={6} className="cityFirstIllustration noPadding">
            <Image
              fluid
              className=""
              src="/assets/PortoIllustration.png"
            ></Image>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Porto by Nef and Doxia</h1>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col xs={12} sm={12} md={6} className="textCenter">
            <Image fluid src="/assets/NefDoxia.png"></Image>
          </Col>
          <Col xs={12} sm={12} md={4} className="textLeft textIntroNef">
            <p className="verticalAlign">
              Hey there! I’m Nef, and this is my buddy Doxia! Welcome to Porto,
              the city where every corner hides a gem waiting to be uncovered!
              🌟
              <br></br>
              We’re here to guide you through this treasure trove of
              adventures—think breathtaking parks, stunning views, mouthwatering
              food, and drinks that’ll make you say ‘wow!’ 🍷🍻
              <br></br>
              Whether you're a food fanatic or a nature lover, we've got the
              inside scoop on all the must-see spots. So, buckle up and get
              ready for a wild ride through Porto's best-kept secrets!
            </p>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
        </Row>
        <Row className="">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Must Go Outdoor</h1>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col sm={12} md={3}>
            <div className="nef-pic">
              <Image
                className="verticalAlign"
                src="/assets/nef/nef-a.png"
              ></Image>
            </div>
          </Col>
          <Col sm={12} md={3} className="textLeft textIntroNef">
            <div className="verticalAlign">
              <p>
                <span className="textBold">Ribeira</span> is the place to chill
                by the river—grab a snack and soak it all in.
                <br></br>
                Then, head to
                <span className="textBold"> Rua das Flores</span> for some
                serious charm.
                <br></br>
                <span className="textBold">Avenida dos Aliados</span>? That city
                hall is next-level cool. Take a stroll down{" "}
                <span className="textBold">Rua de Santa Catarina</span>, stop by{" "}
                <span className="textBold">Majestic Café</span> for a coffee,
                and if you’re into art,{" "}
                <span className="textBold">Rua Miguel Bombarda</span> is packed
                with galleries.
                <br></br>
                For jaw-dropping views, hit up{" "}
                <span className="textBold">Jardim do Morro</span>.<br></br>
                Feeling coastal? <span className="textBold">Foz</span> is
                perfect for a long walk by the sea, and{" "}
                <span className="textBold">Praia de Matosinhos</span> is great
                for some beach time. Need green space?{" "}
                <span className="textBold">Parque da Cidade</span> is the
                biggest park around, and{" "}
                <span className="textBold">Serralves</span> combines a beautiful
                museum with an epic park.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Row>
              <Col xs={6} sm={6} md={6} className="textCenter">
                <Image fluid src="/assets/porto/aliados.png"></Image>
                <p>Aliados</p>
              </Col>
              <Col xs={6} sm={6} md={6} className="textCenter">
                <Image fluid src="/assets/porto/foz.png"></Image>
                <p>Foz do Douro</p>
              </Col>
              <Col xs={6} sm={6} md={6} className="textCenter">
                <Image fluid src="/assets/porto/ribeira.png"></Image>
                <p>Ribeira</p>
              </Col>
              <Col xs={6} sm={6} md={6} className="textCenter">
                <Image fluid src="/assets/porto/morro.png"></Image>
                <p>Jardim do Morro</p>
              </Col>
              <Col xs={6} sm={6} md={6} className="textCenter">
                <Image fluid src="/assets/porto/santacatarina.png"></Image>
                <p>Rua Santa Catarina</p>
              </Col>
              <Col xs={6} sm={6} md={6} className="textCenter">
                <Image fluid src="/assets/porto/parque-cidade.png"></Image>
                <p>Parque da Cidade</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Must Go Indoor</h1>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col sm={12} md={3}>
            <div className="nef-pic">
              <Image
                className="verticalAlign"
                src="/assets/doxia/doxia-o.png"
              ></Image>
            </div>
          </Col>
          <Col sm={12} md={3} className="textLeft textIntroNef">
            <div className="verticalAlign">
              <p>
                Let’s check out some of Porto’s indoor gems! First up is{" "}
                <span className="textBold">Estação de São Bento </span>—those
                tiles are so stunning, you might just forget you’re at a train
                station!
                <br></br>
                <br></br>
                Next, we have the{" "}
                <span className="textBold">Torre dos Clérigos</span>. Climb
                those stairs for an epic view! Just remember, no banana breaks
                on the way up—gravity is not our friend!
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className="verticalAlign">
              <Row>
                <Col xs={12} sm={12} md={6} className="textCenter">
                  <Image fluid src="/assets/porto/sao_bento.png"></Image>
                  <p>Estação São Bento</p>
                </Col>
                <Col xs={12} sm={12} md={6} className="textCenter">
                  <Image fluid src="/assets/porto/clerigos.png"></Image>
                  <p>Torre dos Clérigos</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col xs={12} sm={12} md={3}>
            <div className="nef-pic">
              <Image
                className="verticalAlign"
                src="/assets/nef/nef-happy.png"
              ></Image>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} className="textLeft textIntroNef">
            <div className="verticalAlign">
              <p>
                Now, let’s talk about{" "}
                <span className="textBold">Livraria Lello</span>, one of the
                most beautiful bookstores in the world! It’s like walking into a
                magical jungle of books. Just be careful—if you see me in there,
                I might be trying to read every single title while eating a
                banana!
                <br></br>
                <br></br>
                And then there’s{" "}
                <span className="textBold">Casa da Música</span>, the amazing
                music hall! It’s the perfect place to let loose and dance. Trust
                me, I’ve tried to bust a move or two—just don’t ask the security
                monkeys about that!
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className="verticalAlign">
              <Row>
                <Col xs={12} sm={12} md={6} className="textCenter">
                  <Image fluid src="/assets/porto/lello.png"></Image>
                  <p>Livraria Lello</p>
                </Col>
                <Col xs={12} sm={12} md={6} className="textCenter">
                  <Image fluid src="/assets/porto/casa_musica.png"></Image>
                  <p>Casa da Música</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col xs={12} sm={12} md={3}>
            <div className="nef-pic">
              <Image
                className="verticalAlign"
                src="/assets/nef/nef-a.png"
              ></Image>
            </div>
          </Col>
          <Col xs={12} sm={12} md={3} className="textLeft textIntroNef">
            <div className="verticalAlign">
              <p>
                Don’t forget the{" "}
                <span className="textBold">Palácio da Bolsa</span>! This
                stunning palace makes you feel like royalty—at least until you
                realize you’re just an ape in a fancy building. But hey, a
                well-dressed ape is still a sight to see!
                <br></br>
                <br></br>
                And for all you sports fans, we can’t skip the{" "}
                <span className="textBold">Estádio do Dragão</span>! It’s the
                home of my other heart club, where I cheer louder than a howler
                monkey. And let’s not forget the museum there—full of trophies
                and tales that’ll make any ape’s heart swell with pride!
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <div className="verticalAlign">
              <Row>
                <Col xs={12} sm={12} md={6} className="textCenter">
                  <Image fluid src="/assets/porto/bolsa.png"></Image>
                  <p>Palácio da Bolsa</p>
                </Col>
                <Col xs={12} sm={12} md={6} className="textCenter">
                  <Image fluid src="/assets/porto/estadio-dragao.png"></Image>
                  <p>Estádio do Dragão</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row></Row>
        <Row className="bigMarginTop">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
          </Col>
        </Row>
        <Row className="">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigMarginTop">
          <Col md={12} className="textCenter">
            <h1>Where and what to eat?!</h1>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col xs={0} sm={0} md={2}></Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image fluid src="/assets/nef/nef-hungry.png"></Image>
          </Col>
          <Col xs={12} sm={12} md={4} className="textLeft textIntroNef">
            <p className="verticalAlign">
              Alright, Apes, here we go! 🍴
              <br></br>
              There’s a ton of info coming your way, but hey, I can’t help
              it—I’m a total foodie! I tried to filter it down, but when it
              comes to food, there’s just too much deliciousness to leave out!
              <br></br>
              From hearty Francesinha (a beast of a sandwich!) to Tripas à Moda
              do Porto, our traditional dishes pack a punch. And don’t even get
              me started on Bacalhau—there’s a thousand ways to cook this salty
              legend! Oh, and Rojões, the marinated pork that’ll make your taste
              buds go wild!
              <br></br>
              <br></br>
              Trust me, you’re going to want to loosen that belt for this one.
              Let’s dive in!
            </p>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>Food Markets</h2>
          </Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/timeout_porto.png"
            ></Image>
            <p>
              <span className="textBold">Time Out Market Porto</span>
              <br></br>
              <span className="smallText">
                Brand new space at Estação de Sao Bento with some restaurants
                from portuguese top chefs. All food is amazing but try this
                first - octupus rice from Casa Inês; French Toast with icecream
                from Vasco Santos; Duck rice from Rui Paula; Roasted pig
                sandwich from Ricardo Costa.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/bolhao.png"></Image>
            <p>
              <span className="textBold">Mercado Bolhão</span>
              <br></br>
              <span className="smallText">
                Tradicional food market. This is a must.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/bom_sucesso.png"
            ></Image>
            <p>
              <span className="textBold">Mercado Bom Sucesso</span>
              <br></br>
              <span className="smallText">
                Portuguese and International food. Lots of good options.
              </span>
            </p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>Traditional Portuguese Restaurants</h2>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/paparico.png"></Image>
            <p>
              <span className="textBold">Paparico</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/elebe.png"></Image>
            <p>
              <span className="textBold">Elebe</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/antunes.png"></Image>
            <p>
              <span className="textBold">Antunes</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/taberna_dos_mercadores.png"
            ></Image>
            <p>
              <span className="textBold">Taberna dos Mercadores</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/casa_nanda.png"></Image>
            <p>
              <span className="textBold">Casa Nanda</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/casa_ines.png"></Image>
            <p>
              <span className="textBold">Casa Inês</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>Seafood Restaurants</h2>
            <p>
              Porto is well known for its seafood! For this, Matosinhos is the
              place.
            </p>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/marisqueira_de_matosinhos.png"
            ></Image>
            <p>
              <span className="textBold">Marisqueira de Matosinhos</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/gaveto.png"></Image>
            <p>
              <span className="textBold">Gaveto</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>Traditional Food</h2>
            <p>Like pick and go sandwiches better than bananas!</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/gazela.png"></Image>
            <p>
              <span className="textBold">Gazela</span>
              <br></br>
              <span className="smallText">
                Must try: Cachorrinhos (small hot-dogs)
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/badalhoca.png"></Image>
            <p>
              <span className="textBold">Badalhoca</span>
              <br></br>
              <span className="smallText">Must try: Rojões Sandwich</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/casa_guedes.png"
            ></Image>
            <p>
              <span className="textBold">Casa Guedes</span>
              <br></br>
              <span className="smallText">Must try: Pernil Sandwich</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/cozinha_do_manel.png"
            ></Image>
            <p>
              <span className="textBold">Cozinha do Manel</span>
              <br></br>
              <span className="smallText">
                Must try: Tripas à moda do Porto
              </span>
            </p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>FRANCESINHAS</h2>
            <p>
              The crucial question, where to eat Francesinha?
              <br></br>
              There's tons of places and I visited more than 50 already but
              these are my favourites.
            </p>
          </Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/yuko.png"></Image>
            <p>
              <span className="textBold">Yuko</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/taberna_londrina.png"
            ></Image>
            <p>
              <span className="textBold">Taberna Londrina</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/brasao.png"></Image>
            <p>
              <span className="textBold">Brasão</span>
              <br></br>
              <span className="smallText"></span>
            </p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>International Restaurants</h2>
            <p>
              I know it is not portuguese food, but there are a lot of good
              international restaurants!
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/belos_aires.png"
            ></Image>
            <p>
              <span className="textBold">Belos Aires</span>
              <br></br>
              <span className="smallText">Argentina</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image
              fluid
              src="/assets/porto/restaurants/euskalduna_studio.png"
            ></Image>
            <p>
              <span className="textBold">Euskalduna Studio</span>
              <br></br>
              <span className="smallText">Asian</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/mendi.png"></Image>
            <p>
              <span className="textBold">Mendi</span>
              <br></br>
              <span className="smallText">Indian</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/fava_tonka.png"></Image>
            <p>
              <span className="textBold">Fava Tonka</span>
              <br></br>
              <span className="smallText">Vegetarian</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/ichiban.png"></Image>
            <p>
              <span className="textBold">Ichiban</span>
              <br></br>
              <span className="smallText">Japanese</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/restaurants/portarossa.png"></Image>
            <p>
              <span className="textBold">Portarossa</span>
              <br></br>
              <span className="smallText">Italian</span>
            </p>
          </Col>
        </Row>
        <Row className="bigMarginTop"></Row>
        <Row className="bigMarginTop">
          <Col md={12} className="textCenter">
            <h1>Chill, Beer and Wine!</h1>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col xs={0} sm={0} md={2}></Col>
          <Col xs={12} sm={12} md={4} className="textCenter">
            <Image fluid src="/assets/doxia/doxia-beer-2.png"></Image>
          </Col>
          <Col xs={12} sm={12} md={4} className="textLeft textIntroNef">
            <p className="verticalAlign">
              Damn, that Nef really loves food! 🍽️
              <br></br>If you’ve managed to scroll past all that info,
              congrats—you deserve a drink!
              <br></br>
              <br></br>
              Now it’s time to kick back, relax, and enjoy Porto’s awesome views
              with your favorite beverage in hand. 🍷🍺
              <br></br>
              Porto is famous for its Port wine tastings, and trust me, sipping
              wine with a view of the Douro River is pure magic.
              <br></br>
              If wine’s not your thing, no worries—this city has tons of craft
              beer pubs where you can experiment and find your new favorite
              brew! 🍻
              <br></br> <br></br>
              From cozy little spots to places with amazing panoramic views,
              Porto has it all. So grab a glass (or two), take in the scenery,
              and let the good vibes flow!
            </p>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>Wine Tasting</h2>
            <p>
              There are great wine tasting tours around old and historic cellars
              in Porto and Gaia (other side of the river). But if you are more
              into going to a bar and pop some bottles, here are a few bars.
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/prova.png"></Image>
            <p>
              <span className="textBold">Prova Wine Bar</span>
              <br></br>
              <span className="smallText">
                Known for its vast selection of Portuguese wines and an inviting
                atmosphere with light jazz, it's a great spot for wine lovers to
                explore the local wine scene.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/quaybar.png"></Image>
            <p>
              <span className="textBold">Wine Quay Bar</span>
              <br></br>
              <span className="smallText">
                Located near the Douro River, this wine bar offers an amazing
                view while you sip on some of the best local wines paired with
                Portuguese snacks like cheeses and sausages.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/ladowines.png"></Image>
            <p>
              <span className="textBold">Lado Wines</span>
              <br></br>
              <span className="smallText">
                Specializing in lesser-known Port wines from small producers,
                this intimate spot is perfect for discovering unique wines from
                the Douro region.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/folias.png"></Image>
            <p>
              <span className="textBold">Folias do Baco</span>
              <br></br>
              <span className="smallText">
                This cozy place offers natural wines from the Douro Valley and
                features locally sourced food, providing a great farm-to-table
                experience with wine pairings.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/bonvivant.png"></Image>
            <p>
              <span className="textBold">A Cave do Bon Vivant</span>
              <br></br>
              <span className="smallText">
                A favorite for those wanting to share a wine-tasting experience
                with friends, focusing on organic and biodynamic wines paired
                with delicious food.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/bombarda.png"></Image>
            <p>
              <span className="textBold">Cave Bombarda</span>
              <br></br>
              <span className="smallText">
                Cave Bombarda is a spot offering local Portuguese wines, and
                it's well-known for its wine tastings from different regions of
                Portugal. The owners take pride in explaining the varieties and
                characteristics of the wines they serve.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/capela.png"></Image>
            <p>
              <span className="textBold">Capela Incomum</span>
              <br></br>
              <span className="smallText">
                Located in a renovated chapel, this intimate and unique spot
                offers a wide selection of wines in a peaceful and atmospheric
                environment.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/porto/bars/candelabro.png"></Image>
            <p>
              <span className="textBold">Candelabro</span>
              <br></br>
              <span className="smallText">
                This popular bar offers a relaxed atmosphere with a good
                selection of wines and serves as a charming spot to enjoy a
                glass while taking in the local vibes.
              </span>
            </p>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h2>Craft Beer</h2>
            <p>
              Now, craft beer—that’s my jam! 🍺
              <br></br> Porto’s got some awesome spots where you can chill with
              a cold one in hand, whether you’re lounging in a green park or
              soaking up a panorama view of the city or river. Nothing like a
              brew with a view, right?
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/bars/catraio.png"></Image>
            <p>
              <span className="textBold">Catraio</span>
              <br></br>
              <span className="smallText">
                Known as Porto’s first craft beer pub, Catraio offers over 130
                varieties, focusing on Portuguese brews like Sovina and Letra.
                It has a cozy vibe with both indoor and outdoor seating, perfect
                for mingling​. A must go!
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/bars/armazem.png"></Image>
            <p>
              <span className="textBold">Armazém da Cerveja</span>
              <br></br>
              <span className="smallText">
                A craft beer bar known for its impressive selection of beers on
                tap and in bottles, often focusing on Portuguese breweries.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/bars/nortada.png"></Image>
            <p>
              <span className="textBold">Nortada</span>
              <br></br>
              <span className="smallText">
                This brewery bar offers a great selection of craft beers brewed
                in-house. With a fantastic view of the city from their rooftop,
                it’s a great place to sip and relax.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/bars/letraria.png"></Image>
            <p>
              <span className="textBold">Letraria Craft Beer Garden</span>
              <br></br>
              <span className="smallText">
                A beer lover’s paradise with over 50 varieties, this spot offers
                a beautiful garden where you can relax with craft beers from
                Portugal and beyond. It’s a great place to unwind outdoors.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/bars/musa.png"></Image>
            <p>
              <span className="textBold">Musa das Virtudes</span>
              <br></br>
              <span className="smallText">
                Situated near a tree-lined park, Musa das Virtudes combines
                great craft beers with stunning views over the Douro River.
                Their massive patio is a perfect spot for drinking while taking
                in the sights​.
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={4} className="textCenter">
            <Image fluid src="/assets/porto/bars/hoptrip.png"></Image>
            <p>
              <span className="textBold">HopTrip</span>
              <br></br>
              <span className="smallText">
                Located in Matosinhos, this bar serves 15 beers on tap, with
                rotating weekly selections. It has a cool beer hall atmosphere
                with snacks like onion rings and codfish balls​.
              </span>
            </p>
          </Col>
        </Row>
        <Row className="bigMarginTop">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>

        <Row className="marginTop">
          <Col xs={12} sm={12} md={6} className="textCenter">
            <Image fluid src="/assets/NefDoxia.png"></Image>
          </Col>
          <Col xs={12} sm={12} md={4} className="textLeft textIntroNef">
            <p className="verticalAlign">
              As we wrap up our Porto adventure, remember that this city is full
              of hidden gems just waiting to be discovered!
              <br></br>
              From the delicious bites Nef can't stop raving about to the
              relaxing vibes Doxia loves, Porto has something for every ape to
              enjoy.
              <br></br>
              <br></br>
              If you’re planning a trip or have any questions, feel free to
              reach out! We're here to help you make the most of your experience
              in this beautiful city. So grab a bifana, raise a glass of Port,
              and let’s explore together!
            </p>
          </Col>
          <Col xs={0} sm={0} md={2}></Col>
        </Row>

        <Row></Row>
        <Row className="bigMarginTop">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Porto;
