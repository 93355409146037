import React from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./Home";
import Lisbon from "./Lisbon";
import Porto from "./Porto";
import Food from "./Food";
import CustomNavbar from "./CustomNavbar";

function App() {
  return (
    <div className="App">
      <CustomNavbar></CustomNavbar>

      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/lisbon" element={<Lisbon></Lisbon>}></Route>
        <Route path="/porto" element={<Porto></Porto>}></Route>
        <Route path="/food" element={<Food></Food>}></Route>
      </Routes>
    </div>
  );
}

export default App;
