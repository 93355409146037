import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Lisbon.css";

const Food = () => {
  return (
    <div>
      <Container fluid className="noPadding ">
        <Row className="backgroundBlue homeHeader">
          <Col md={12} className="textCenter noPadding">
            <div className="headerCityDiv">
              <Image
                fluid
                className="headerIllustration"
                src="/assets/food.png"
              ></Image>
              <div className="headerOverlay">
                <div className="headerOverlayBlue"></div>
                <Image
                  className="headerTilesOverlay"
                  src="/assets/tilesOverlay01.png"
                ></Image>
                <h1 className="headerH1">Food</h1>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigMarginTop noPadding">
          <Col md={6} className="cityDescription noPadding">
            <div>
              <h1>Ape Like a Local</h1>
              <h2>Savor the flavors of our culture.</h2>
              <p>
                Portuguese cuisine is a rich tapestry of flavors and traditions,
                deeply rooted in the country's history and culture. From the
                fresh seafood along the Atlantic coast to the hearty meats and
                stews of the inland regions, Portuguese food offers a delightful
                variety of dishes that cater to every palate.
                <br></br>
                Start your culinary journey with the beloved bacalhau, or salt
                cod, prepared in countless delicious ways. Savor the flavors of
                freshly grilled sardines, a summertime favorite, or indulge in
                the tender and spicy delights of piri-piri chicken. Don't miss
                the iconic pastel de nata, a creamy custard tart nestled in a
                crispy pastry shell, perfect for a sweet treat.
                <br></br>
                <br></br>
                Each region boasts its own specialties, from the robust wines of
                the Douro Valley to the aromatic spices of the Alentejo.
                Portuguese cuisine is not just about the food; it's about the
                experience of sharing meals with friends and family, enjoying
                the simple pleasures of life. Come and explore the tastes of
                Portugal, where every dish tells a story and every meal is a
                celebration.
              </p>
            </div>
          </Col>
          <Col md={6} className="cityFirstIllustration noPadding">
            <Image
              fluid
              className=""
              src="/assets/food/foodIllustration.png"
            ></Image>
          </Col>
        </Row>

        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Traditional Food</h1>
            <p>If you have a chance, try one of our portuguese dishes.</p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/posta_mirandesa.png"></Image>
            <p>Posta à Mirandesa</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/francesinha.png"></Image>
            <p>Francesinha</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/bifanas.jpg"></Image>
            <p>Bifanas</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/prego.jpg"></Image>
            <p>Prego</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/caldoverde.jpg"></Image>
            <p>Caldo Verde</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/feijoada.jpg"></Image>
            <p>Feijoada</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/alheira.png"></Image>
            <p>Alheira</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/arroz_pato.png"></Image>
            <p>Arroz de Pato</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/arroz_marisco.png"></Image>
            <p>Arroz de Marisco</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/arroz_tamboril.png"></Image>
            <p>Arroz de Tamboril</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/bacalhau_brasa.png"></Image>
            <p>Bacalhau na Brasa</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/bacalhau_com_natas.png"></Image>
            <p>Bacalhau com Natas</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/bacalhau_bras.png"></Image>
            <p>Bacalhau à Brás</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/bitoque.png"></Image>
            <p>Bitoque</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/cabidela.png"></Image>
            <p>Cabidela</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/cozido_portuguesa.png"></Image>
            <p>Cozido à Portuguesa</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/leitao.png"></Image>
            <p>Leitão</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/rojoes.png"></Image>
            <p>Rojões</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/polvo_lagareiro.png"></Image>
            <p>Polvo à Lagareiro</p>
          </Col>

          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/sardinhas.jpg"></Image>
            <p>Sardinhas</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/rissois.jpg"></Image>
            <p>Rissois</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/peixinhoshorta.jpg"></Image>
            <p>Peixinhos de Horta</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/caracoes.jpg"></Image>
            <p>Caracois</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/moelas.png"></Image>
            <p>Moelas</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/Frango_piripiri.png"></Image>
            <p>Frango de Churrasco</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/percebes.png"></Image>
            <p>Percebes</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/queijo_serra.png"></Image>
            <p>Queijo da Serra</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/sopa_pedra.png"></Image>
            <p>Sopa da Pedra</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/sopa_peixe.png"></Image>
            <p>Sopa de Peixe</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/tremocos.png"></Image>
            <p>Tremoços</p>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Ape Sugar</h1>
            <p>You have to try one of our sugar treasures.</p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/paodeus.jpg"></Image>
            <p>Pão de Deus</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/pasteldenata.jpg"></Image>
            <p>Pastel de Nata</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/bolasberlim.jpg"></Image>
            <p>Bolas de Berlim</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/farofias.png"></Image>
            <p>Farófias</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/leite_creme.png"></Image>
            <p>Leite Creme</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/natas_ceu.png"></Image>
            <p>Natas do Céu</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/Ovos_moles.png"></Image>
            <p>Ovos Moles</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/pudim.png"></Image>
            <p>Pudim</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/sericaia.png"></Image>
            <p>Sericaia</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/food/deserts/toucinho_ceu.png"></Image>
            <p>Toucinho do Céu</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image
              fluid
              src="/assets/food/deserts/pasteis_tentugal.png"
            ></Image>
            <p>Pasteis de Tentugal</p>
          </Col>
          {/* <Col xs={6} sm={6} md={2} className="textCenter">
            <Image
              fluid
              src="/assets/food/deserts/queijadas_sintra.png"
            ></Image>
            <p>Queijadas de Sintra</p>
          </Col> */}
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image
              fluid
              src="/assets/food/deserts/travesseiros_de_sintra.png"
            ></Image>
            <p>Travesseiros de Sintra</p>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Portuguese Drinks</h1>
            <p>
              <span className="textBold">To Ape with Moderation!</span>
              <br></br>
              There are a lot of flavors, please try not to mix.
            </p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/aguardente.png"></Image>
            <p>Aguardente</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/beirao.png"></Image>
            <p>Licor Beirão</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/ginjinha.png"></Image>
            <p>Ginjinha</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/medronho.png"></Image>
            <p>Medronho</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/moscatel.png"></Image>
            <p>Moscatel</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/porto_tonico.png"></Image>
            <p>Porto Tónico</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/sagres.png"></Image>
            <p>Sagres</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/super_bock.png"></Image>
            <p>Super Bock</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/tinto_alentejo.png"></Image>
            <p>Vinho Tinto</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/vinho_madeira.png"></Image>
            <p>Vinho da Madeira</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/vinho_porto.png"></Image>
            <p>Vinho do Porto</p>
          </Col>
          <Col xs={6} sm={6} md={2} className="textCenter">
            <Image fluid src="/assets/drinks/vinhoverde.png"></Image>
            <p>Vinho Verde</p>
          </Col>
        </Row>

        <Row></Row>
        <Row className="bigMarginTop">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Food;
